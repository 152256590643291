@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700&display=swap');
.nav-link-submenu {
    display: block;
    padding: var(--cui-nav-link-padding-y) var(--cui-nav-link-padding-x);
    font-size: var(--cui-nav-link-font-size);
    font-weight: var(--cui-nav-link-font-weight);
    color: var(--cui-nav-link-color);
    text-decoration: none;
    margin-left:20px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.side-bar {
    background-color: #154745;
}

.active-menu {
    color: #fff !important;
}

.azul {
    background-color: #154745;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #555 !important;
    font-weight: 200;
  }

.underline {
    text-decoration: underline !important;
}

.button {
    background-color: #154745 !important;
    border:0 !important;
    color: #fff;
}

.ql-editor {
    background-color: #ffffff; /* Fundo branco */
    color: #333333; /* Cor do texto */
    padding: 10px; /* Preenchimento interno */
    min-height: 200px; /* Altura mínima para a área de texto */
  }

  .right-sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0); /* Aberta */
  }
  .right-sidebar.hidden {
    transform: translateX(100%); /* Oculta */
  }


  @media (max-width:700px) {
    .div-corpo-site {
      display: flex !important;
      flex-direction: column;
      padding: 5px !important;
      margin:5px !important;
    }

    .noticias-list {
      display: flex !important;
      flex-direction: column;
    }

    .card-title {
      font-size: 1.2rem !important;
    }

    .container-noticia {
      padding: 5px !important;
    }
  }